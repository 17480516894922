import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Video.scss';
import Play from 'components/interface/Play';
import SubtitlesContainer from 'components/interface/SubtitlesContainer';
import ProgressBar from 'components/interface/ProgressBar';
import Vimeo from '@u-wave/react-vimeo';
import Conf from 'utils/Conf';

class Video extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
            show:true,
        }
        this.timeoutId=0;
        this.handleMouseMove=this.handleMouseMove.bind(this);
        this.handleControlsClick=this.handleControlsClick.bind(this);
        this.handlePlayClick=this.handlePlayClick.bind(this);
        this.handleTargetOn=this.handleTargetOn.bind(this);
        this.handleTargetOff=this.handleTargetOff.bind(this);
    }
    handleTargetOn(){
        this.setState({targetOn:true});
    }
    handleTargetOff(){
        this.setState({targetOn:false});
    }
    componentDidMount(){
        console.log('mount video',this.props.objectId);
        let players=this.props.appState.players;
        let player=_.find(players,{id:this.props.objectId});
        if (!player) {
            players.push({id:this.props.objectId, objectType:this.props.objectType, videoId:this.props.videoId, shouldPlay:false, positionCanUpdate:true, currentTime:0, position:0});
            this.props.methods.appStateSet({players});
        }
        document.addEventListener('mousedown',this.handleMouseMove);
        document.addEventListener('mousemove',this.handleMouseMove);
    }
    componentWillUnmount() {
        clearTimeout(this.timeoutId);
        console.log('unmount video',this.props.objectId);
        let players=this.props.appState.players;
        let playerIdx=_.findIndex(players,{id:this.props.objectId});
        if (playerIdx!==-1) {
            players.splice(playerIdx,1);
            this.props.methods.appStateSet({players});
        }
        document.removeEventListener('mousedown',this.handleMouseMove);
        document.removeEventListener('mousemove',this.handleMouseMove);
    }
    handleMouseMove(e){
        var that=this;
        clearTimeout(this.timeoutId);
        if (!this.state.show) {
            this.setState({show:true});
        }
        this.timeoutId=setTimeout(function () {
            that.setState({show:false});
        }, Conf.timeBeforeFade);
    }
    handleControlsClick(e,id){
        console.log('controlsClick',this.props.appState.clickDisabled);
        if (!this.props.appState.clickDisabled && e.target===e.currentTarget && this.props.active) {
            e.stopPropagation();
            let play=this.props.methods.onToggleShouldPlay(id);
            console.log(play);
            if (!play) this.props.onShouldPause && this.props.onShouldPause();
            else this.props.onShouldPlay && this.props.onShouldPlay();
        }
    }
    handlePlayClick(e,id){
        console.log('playClick',this.props.appState.clickDisabled);
        if (!this.props.appState.clickDisabled && this.props.active) {
            e.stopPropagation();
            let play=this.props.methods.onToggleShouldPlay(id);
            console.log(play);
            if (!play) this.props.onShouldPause && this.props.onShouldPause();
            else this.props.onShouldPlay && this.props.onShouldPlay();
        }
    }
    render() {
        let classes="video";
        let playStyle={};
        let player=_.find(this.props.appState.players,{id:this.props.objectId});
        if (this.props.active) {
            classes+=" active";
            if (!(player && player.shouldPlay)) {
                let size=this.props.playActiveSize || 60;
                playStyle={
                    left: this.props.playActiveLeft!==undefined ? this.props.playActiveLeft : 15,
                    bottom: this.props.playActiveBottom!==undefined ? this.props.playActiveBottom : 60,
                    width:size,
                    height:size,
                    opacity:this.props.playActiveOpacity,
                }
            }
        } else {
            let size=this.props.playIdleSize || 100;
            playStyle={
                left: this.props.playIdleLeft!==undefined ? this.props.playIdleLeft : 15,
                bottom: this.props.playIdleBottom!==undefined ? this.props.playIdleBottom : 60,
                width:size,
                height:size,
                opacity:this.props.playIdleOpacity || 0,
            }
        }
        if (player && player.shouldPlay) classes+=" playing";
        let imgClasses="video-thumb-img";
        if (player && player.duration && player.currentTime>0) imgClasses+=" hide";
        let controlsClass="video-controls";
        if ((player && player.shouldPlay && !this.state.show && !this.state.targetOn) || (player && !player.shouldPlay)) {
            controlsClass+=" hide";
        }
        let playClass="video-play";
        if (player && player.shouldPlay && !this.state.show && !this.state.targetOn) playClass+=" hide"
        let containerStyles={paddingBottom:(100*this.props.appState.mH/this.props.appState.mW)+'%'};
        let styles={};
        let oTop=0;
        let oLeft=0;
        let largeur=100;
        let hauteur=100;
        if (this.props.format) {
            if (this.props.format[0]/this.props.format[1] >= this.props.appState.mW/this.props.appState.mH) {
                hauteur=100*(this.props.appState.mW*this.props.format[1]/this.props.format[0])/this.props.appState.mH;
                oTop=(100-hauteur)/2;
                styles={width:'100%', height:hauteur+'%', top:oTop+'%'};
            } else {
                largeur=100*(this.props.appState.mH*this.props.format[0]/this.props.format[1])/this.props.appState.mW;
                oLeft=(100-largeur)/2;
                styles={height:'100%', width:largeur+'%', left:oLeft+'%'};
            }
        }
        return <div className={classes} style={containerStyles}>
            {player && <div className="video-container">
                <div
                className='video-vimeo'
                style={styles}
                >
                <div className='video-vimeo-inner'>
                <Vimeo
                    key={this.props.objectId}
                    video={this.props.videoId}
                    responsive
                    paused={!player.shouldPlay}
                    onPause={() => this.props.methods.onPause(this.props.objectId)}
                    onPlay={() => this.props.methods.onPlay(this.props.objectId)}
                    onTimeUpdate={(e) => this.props.methods.onTimeUpdate(e,this.props.objectId)}
                    onReady={(e) => this.props.methods.onReady(e,this.props.objectId)}
                    onEnd={(e)=>{this.props.methods.onEnd(e,this.props.objectId);this.props.onEnd()}}
                    onError={(e) => console.log(e)}
                    controls={false}
                    showPortrait={false}
                    showTitle={false}
                    showByline={false}
                    autopause={false}
                    volume={this.props.appState.mainVolume}
                />
                <img className={imgClasses} alt='' src={this.props.thumbnail}/>
                </div>
                </div>
                <div className="video-mask">
                    {player && !player.isPlaying && player.shouldPlay && <div className="loading">...</div>}
                    <SubtitlesContainer
                        subtitles={this.props.subtitles}
                        subtitlesOffset={this.props.subtitlesOffset}
                        currentTime={player.currentTime*1000}
                        bottom={!this.state.show}
                    />
                </div>
                <div className={controlsClass} onClick={(e)=>this.handleControlsClick(e,this.props.objectId)}>
                    {player.duration && <ProgressBar
                        {...this.props}
                        position={player.position}
                        currentTime={player.currentTime}
                        duration={player.duration}
                        onPositionUpdate={(p) => this.props.methods.onPositionUpdate(this.props.objectId,p)}
                        onTargetMoveStart={this.handleTargetOn}
                        onTargetMoveEnd={this.handleTargetOff}
                    />}
                </div>
                <Play style={playStyle} playing={player.shouldPlay} size={100} className={playClass} onClick={(e)=>this.handlePlayClick(e,this.props.objectId)}/>
            </div>}
        </div>;
    }
}
export default Video;
