import React, { Component } from 'react';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import * as Subtitle from 'subtitle';

//import _ from 'lodash';
import './App.scss';
import axios from 'axios';
import Conf from 'utils/Conf';
import Parametres from 'utils/Parametres';
import Data from 'assets/Data.js';

//components
import Intro from 'components/Intro';
import PreGenerique from 'components/PreGenerique';
import Accueil from 'components/Accueil';
import MainVideos from 'components/MainVideos';
import MainDrawer from 'components/MainDrawer';
import Lives from 'components/Lives';
import Credits from 'components/Credits';
import Mentions from 'components/Mentions';
import Catalogue from 'components/Catalogue';
import Modal from 'components/Modal';
import FullScreenBtn from 'components/interface/FullScreenBtn';
import MuteBtn from 'components/interface/MuteBtn';

import Router from 'components/Router';
import Ed from 'utils/EventDispatcher';
import Countdown from 'components/Countdown';
import AssetManager from 'utils/AssetManager';

class App extends Component {
    constructor(props){
        super(props);
        this.pauseAllMainVideos=this.pauseAllMainVideos.bind(this);
        this.startCountDownVideo=this.startCountDownVideo.bind(this);
        this.state={
            ready:false,
            showControls:false,
            controlsLock:false,
            lastVolume:1,
            preloaded:false,
            progress:false,
            appState:{
                maindDrawerOpen:false,
                players:[],
                mainVolume:1,
                clickDisabled:false,
                mW:Parametres.mainWidth,
                mH:Parametres.mainHeight,
                returnVideo:null,
            },
            templates:[
                {id:'intro',component: Intro },
                {id:'pre_generique',component: PreGenerique },
                {id:'accueil',component: Accueil },
                {id:'main_videos',component: MainVideos },
                {id:'lives',component: Lives },
                {id:'credits',component: Credits },
                {id:'mentions',component: Mentions },
                {id:'catalogue',component: Catalogue },
            ],
            pages:[
                {id:'intro',template: 'intro' },
                {id:'pre_generique',template: 'pre_generique' },
                {id:'accueil',template: 'accueil' },
                {id:'main_videos',template: 'main_videos',keep:true },
                {id:'lives',template: 'lives',keep:true },
                {id:'credits',template: 'credits' },
                {id:'mentions',template: 'mentions' },
                {id:'catalogue',template: 'catalogue' },
            ],
            routes:[
                {url:'/',page:'intro'},
                {url:'/pre-generique/',page:'pre_generique'},
                {url:'/accueil/',page:'accueil'},
                {url:'/sommaire/',page:'main_videos'},
                {
                    url:'/chapitre/:id',
                    page:'main_videos',
                    cb:this.startCountDownVideo,
                    exitCb:(page)=>{
                        this.pauseAllMainVideos();
                        page.ref.current.complement.current.reset();
                    }
                },
                {url:'/lives/',page:'lives'},
                {
                    url:'/live/:id',
                    page:'lives',
                    cb:(page)=>{
                        this.methods.onShouldPlay(page.params.id);
                        let live=_.find(Data.lives,{id:page.params.id});
                        if (live && live.format) {
                            this.setScreen(live.format[0],live.format[1]);
                        }
                    },
                    exitCb:()=>{
                        this.pauseAllMainVideos();
                        this.setScreen(Parametres.mainWidth,Parametres.mainHeight);
                    },
                },
                {url:'/credits/',page:'credits'},
                {url:'/mentions/',page:'mentions'},
                {url:'/catalogue/',page:'catalogue'},
            ],
        }
        this.base='';
        this.history = createBrowserHistory({basename:this.base});
        this.goTo=this.goTo.bind(this);
        this.handleRouteHasChanged=this.handleRouteHasChanged.bind(this);
        this.onModalOpen=this.onModalOpen.bind(this);
        this.onModalClose=this.onModalClose.bind(this);
        this.handleMouseMove=this.handleMouseMove.bind(this);
        this.preloadProgress=this.preloadProgress.bind(this);
        this.handleVolumeChange=this.handleVolumeChange.bind(this);
        this.handleMute=this.handleMute.bind(this);
        this.handleUnMute=this.handleUnMute.bind(this);
        this.controlLock=this.controlLock.bind(this);
        this.controlUnLock=this.controlUnLock.bind(this);
        this.resize=this.resize.bind(this);
        this.ed=new Ed();
        this.am=new AssetManager({cb:()=>this.setState({preloaded:true}),ed:this.ed});
        this.methods={
            appStateSet:this.appStateSet.bind(this),
            ed:this.ed,
            am:this.am,
            onPause:this.handlePause.bind(this),
            onPlay:this.handlePlay.bind(this),
            onTimeUpdate:this.handleTimeUpdate.bind(this),
            onReady:this.handleReady.bind(this),
            onEnd:this.handleEnd.bind(this),
            onError:this.handleError.bind(this),
            onPositionUpdate:this.handlePositionUpdate.bind(this),
            onToggleShouldPlay:this.handleToggleShouldPlay.bind(this),
            onShouldPlay:this.handleShouldPlay.bind(this),
            onShouldPause:this.handleShouldPause.bind(this),
            setScreen:this.setScreen.bind(this),
            handlePauseInPlace:this.handlePauseInPlace.bind(this),
            handlePlayInPlace:this.handlePlayInPlace.bind(this),
            pauseAllMainVideos:this.pauseAllMainVideos.bind(this),
        };
        this.appStatePending=false;
        this.countdown=React.createRef();
        this.timeoutId=0;
    }
    handlePauseInPlace(p){
        if (p) {
            let players=this.state.appState.players;
            p.complementOpen=true;
            this.methods.appStateSet({players},()=>{
                if (p && p.player) {
                    p.player.pause();
                    p.player.setVolume(0);
                }
            });
        }
    }
    handlePlayInPlace(p){
        if (p) {
            let players=this.state.appState.players;
            p.complementOpen=false;
            this.methods.appStateSet({players},()=>{
                if (p && p.player) {
                    p.player.play();
                    p.player.setVolume(1);
                }
            });
        }
    }
    startCountDownVideo(page){
        console.log("startCountDownVideo",page);
        let players=this.state.appState.players;
        let player=_.find(players,{id:page.params.id});
        if (!this.state.appState.modalContent) {
            if (page.query.autoplay===undefined && player) this.setState({countdownId:player.id},this.countdown.current.start);
            if (page.query.autoplay!==undefined && player) this.methods.onShouldPlay(player.id);
        }
        this.methods.appStateSet({returnVideo:null});
    }
    pauseAllMainVideos(){
        console.log("pauseAllMainVideos");
        this.countdown.current.stop();
        let players=this.state.appState.players;
        players.map((p)=>{
            p.shouldPlay=false;
            if (p.type==='main' && p.player) p.player.setVolume(0);
            return null;
        });
        this.appStateSet({players});
    }
    handleToggleShouldPlay(id){
        console.log("toggleShouldPlay",id);
        this.countdown.current.stop();
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.shouldPlay=!player.shouldPlay;
        if (player.player && player.shouldPlay) player.player.setVolume(1);
        if (player.player && !player.shouldPlay) player.player.setVolume(0);
        this.appStateSet({players});
        return player.shouldPlay;
    }
    handleShouldPause(id){
        console.log("shouldPause",id);
        this.countdown.current.stop();
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.shouldPlay=false;
        if (player.player) {
            player.player.setVolume(0);
        }
        this.appStateSet({players});
    }
    handleShouldPlay(id){
        console.log("shouldPlay",id);
        this.countdown.current.stop();
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.shouldPlay=true;
        if (player.player) {
            player.player.setVolume(1);
        }
        this.appStateSet({players});
    }
    onModalOpen(){
        let currentPlayer=null;
        this.countdown.current.stop();
        this.state.appState.players.map((p)=>{
            if (p.type==='main' && p.shouldPlay) {
                currentPlayer=p;
            }
            return null;
        })
        if (currentPlayer) this.handlePauseInPlace(currentPlayer);
    }
    onModalClose(){
        console.log('close');
        let currentPlayer=null;
        this.state.appState.players.map((p)=>{
            if (p.type==='main' && p.shouldPlay) {
                currentPlayer=p;
            }
            return null;
        })
        if (currentPlayer) this.handlePlayInPlace(currentPlayer);
        this.appStateSet({modalContent:null});
    }
    handlePause(id){
        console.log("pause",id);
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.isPlaying=false;
        this.appStateSet({players});
    }
    handlePlay(id){
        console.log("play",id);
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.positionCanUpdate=true;
        if (!player.shouldPlay || player.complementOpen){
            player.player.pause();
        } else {
            player.isPlaying=true;
        }
        this.appStateSet({players});
    }
    handleTimeUpdate(e,id){
        //console.log("timeupdate",id);
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.position= player.positionCanUpdate ? e.percent : player.position ;
        player.currentTime= player.positionCanUpdate ? e.seconds : player.currentTime ;
        this.appStateSet({players});
    }
    getSubtitles(player){
        return new Promise(function(resolve, reject) {
            if (player.subtitles) {
                axios.get(player.subtitles).then((response)=>{
                    resolve(Subtitle.parse(response.data));
                    return null;
                }).catch(()=>resolve([]));
            } else {
                resolve([]);
            }
        });
    }
    handleReady(p,id){
        console.log("ready",id);
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.player=p;
        Promise.all([p.getVideoWidth(), p.getVideoHeight(),p.getDuration(),this.getSubtitles(player)]).then((res) => {
            if (player.anamorphic) {
                player.ry=Parametres.mainHeight*res[0]/(Parametres.mainWidth*res[1]);
            }
            player.width=res[0];
            player.height=res[1];
            player.duration=res[2];
            player.subtitles=res[3];
            //calculs des deltas :
            player.dx=0;
            player.dy=0;
            player.H=100;
            player.W=100;
            let h=player.height*Conf.width/player.width;
            let w=player.width*Conf.height/player.height;
            if (player.width/player.height > Conf.width/Conf.height) {
                player.dy=100*((Conf.height-h)/2)/Conf.height;
                player.H=100*h/Conf.height;
            } else {
                player.dx=100*((Conf.width-w)/2)/Conf.width;
                player.W=100*w/Conf.width;
            }
            this.appStateSet({players});
            if (player.shouldPlay) p.play();
        });
    }
    handleEnd(e,id){
        console.log("end",id);
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.shouldPlay=false;
        player.position=0;
        player.currentTime=0;
        player.positionCanUpdate=false;
        this.appStateSet({players});
        player.player.setCurrentTime(0).then(()=>{
            player.positionCanUpdate=true;
            this.appStateSet({players});
            return;
        });
    }
    handleError(id){
        console.log("error",id);
    }
    handlePositionUpdate(id,p){
        console.log("PositionUpdate",id,p);
        let players=this.state.appState.players;
        let player=_.find(players,{id:id});
        player.position=p;
        player.currentTime=player.duration*p;
        player.positionCanUpdate=false;
        this.appStateSet({players});
        player.player.pause().then(
            ()=>player.player.setCurrentTime(p*player.duration).then(()=>player.player.play())
        )
    }
    appStateSet(s,cb){
        if (!this.appStatePending) {
            this.appStatePending=true;
            this.setState({appState:{ ...this.state.appState, ...s }},()=>{
                this.appStatePending=false;
                cb && cb();
            });
        } else {
            setTimeout(()=>this.appStateSet(s,cb),10);
        }
    }
    handleRouteHasChanged(route){
        console.log(route);
        this.onModalClose();
        this.methods.appStateSet({currentRoute:route});
        console.log(document.body.classList);
        Array.from(document.body.classList).map((c)=>{
            console.log(['xs','sm','md','lg','xl'].indexOf(c),c);
            if (['xs','sm','md','lg','xl'].indexOf(c)===-1) document.body.classList.remove(c);
            return null;
        });
        let path=route.substr(1).replace('_','-').split('/');
        console.log(document.body.classList,path);
        let className='';
        if (path[0]) {
            className=path[0];
            document.body.classList.add('route-'+className);
        } else {
            className='generique';
            document.body.classList.add('route-'+className);
        }
        if (path[1]) {
            className=path[1];
            document.body.classList.add('subroute-'+className);
        }
        document.body.classList.remove('hide-controls');
        console.log(document.body.classList);
    }
    goTo(url){
        console.log(url);
        if ( url.indexOf('http://')>=0 || url.indexOf('https://')>=0)
            window.open(url, '_blank');
        else
            this.history.push(this.base+url);
    }
    componentDidMount(){
        //this.goTo('/');
        console.log(Data);
        window.addEventListener('resize',this.resize);
        this.resize();
        let loadList=[];
        Data.mainVideos.map((mv)=>{
            if (_.findIndex(loadList,{id:mv.thumbnail})===-1) loadList.push({id:mv.thumbnail, url:this.base+mv.thumbnail, cb:(url)=>console.log(url,mv.thumbnail)});
            return null;
        });
        Data.lives.map((live)=>{
            if (_.findIndex(loadList,{id:live.thumbnail})===-1) loadList.push({id:live.thumbnail, url:this.base+live.thumbnail, cb:(url)=>console.log(url,live.thumbnail)});
            return null;
        });
        Data.complements.map((complement)=>{
            if (complement.thumbnail && _.findIndex(loadList,{id:complement.thumbnail})===-1) loadList.push({id:complement.thumbnail, url:this.base+complement.thumbnail, cb:(url)=>console.log(url,complement.thumbnail)});
            if (complement.image){
                complement.image.map((o,i)=>{
                    if(_.findIndex(loadList,{id:complement.image[i]})===-1) loadList.push({id:complement.image[i], url:this.base+complement.image[i], cb:(url)=>console.log(url,complement.image[i])});
                    return null;
                });
            }
            if (complement.waveform) {
                complement.waveform.map((o,i)=>{
                    if(_.findIndex(loadList,{id:complement.waveform[i]})===-1) loadList.push({id:complement.waveform[i], url:this.base+complement.waveform[i], cb:(url)=>console.log(url,complement.waveform[i])});
                    return null;
                });
            }
            return null;
        });
        Data.collectages.map((collectage)=>{
            if (collectage.thumbnail && _.findIndex(loadList,{id:collectage.thumbnail})===-1) loadList.push({id:collectage.thumbnail, url:this.base+collectage.thumbnail, cb:(url)=>console.log(url,collectage.thumbnail)});
            if (collectage.image){
                collectage.image.map((o,i)=>{
                    if(_.findIndex(loadList,{id:collectage.image[i]})===-1) loadList.push({id:collectage.image[i], url:this.base+collectage.image[i], cb:(url)=>console.log(url,collectage.image[i])});
                    return null;
                });
            }
            if (collectage.waveform) {
                collectage.waveform.map((o,i)=>{
                    if(_.findIndex(loadList,{id:collectage.waveform[i]})===-1) loadList.push({id:collectage.waveform[i], url:this.base+collectage.waveform[i], cb:(url)=>console.log(url,collectage.waveform[i])});
                    return null;
                });
            }
            return null;
        });
        Data.entretiens.map((entretien)=>{
            if (entretien.image && _.findIndex(loadList,{id:entretien.image[0]})===-1) loadList.push({id:entretien.image[0], url:this.base+entretien.image[0], cb:(url)=>console.log(url,entretien.image[0])});
            return null;
        });
        for(let i=1;i<=11;i++){
            let id='/imgs/intro/'+i+'.jpg';
            loadList.push({id:id, url:this.base+id, cb:(url)=>console.log(url,id)});
        }
        console.log(loadList);
        this.methods.am.fetch(loadList);
        this.ed.addEventListener('progress',this.preloadProgress);
        document.addEventListener('mousedown',this.handleMouseMove);
        document.addEventListener('mousemove',this.handleMouseMove);
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.resize);
        this.ed.removeEventListener('progress',this.preloadProgress);
        document.removeEventListener('mousedown',this.handleMouseMove);
        document.removeEventListener('mousemove',this.handleMouseMove);
    }
    handleMouseMove(e){
        clearTimeout(this.timeoutId);
        if (!this.state.showControls) {
            this.setState({showControls:true});
        }
        this.timeoutId=setTimeout(()=>{
            this.setState({showControls:false});
        }, Conf.timeBeforeControlsFade);
    }
    controlLock(){
        this.setState({controlsLock:true});
    }
    controlUnLock(){
        this.setState({controlsLock:false});
    }
    handleVolumeChange(v){
        this.appStateSet({mainVolume:v});
        this.setState({lastVolume:v});
    }
    handleMute(){
        this.appStateSet({mainVolume:0});
    }
    handleUnMute(){
        this.appStateSet({mainVolume:Math.max(0.2,this.state.lastVolume)});
    }
    preloadProgress(e,p){
        this.setState({progress:p});
    };
    resize(){
        let widthClass='xs';
        let appState=this.state.appState;
        if (window.innerWidth>=600) widthClass='sm';
        if (window.innerWidth>=960) widthClass='md';
        if (window.innerWidth>=1280) widthClass='lg';
        if (window.innerWidth>=1920) widthClass='xl';
        widthClass!=='xs' && document.body.classList.remove('xs');
        widthClass!=='sm' && document.body.classList.remove('sm');
        widthClass!=='md' && document.body.classList.remove('md');
        widthClass!=='lg' && document.body.classList.remove('lg');
        widthClass!=='xl' && document.body.classList.remove('xl');
        document.body.classList.add(widthClass);
        appState.screenSize=widthClass;
        this.setState({appState:appState},()=>{
            this.methods.setScreen(this.state.appState.mW,this.state.appState.mH);
            return null;
        });
        document.body.style.backgroundColor='#000';
    }
    setScreen(mW,mH){
        let W=window.innerWidth;
        let H=window.innerHeight;
        let R=mW/mH;
        let appStyle={}
        let containerStyle={}
        let appState=this.state.appState;
        appState.mW=mW;
        appState.mH=mH;
        if (W/H > R) {
            console.log('paysage');
            appStyle.width=R*H;
            appStyle.height=H;
            appStyle.left=(W-R*H)/2;
            appStyle.top=0;
            containerStyle.transform='scale('+(H/mH)+')';
        } else {
            console.log('portrait');
            appStyle.width=W;
            appStyle.height=W/R;
            appStyle.left=0;
            appStyle.top=(H-W/R)/2;
            containerStyle.transform='scale('+(W/mW)+')';
        }
        containerStyle.width=mW;
        containerStyle.height=mH;
        this.setState({appState,appStyle,containerStyle,ready:true});
    }
    render(){
        let appClass="app";
        if (this.state.ready) appClass+=" ok";
        let modalContent=this.state.appState.modalContent;
        let controlsClasses="main-controls";
        if (this.state.controlsLock || this.state.showControls) controlsClasses+=" show";
        return <div className={appClass} id='app' style={this.state.appStyle}>
            <div className="app-main-container" style={this.state.containerStyle}>
                {!this.state.preloaded && <div className="main-preload">
                    <div className="main-preload-inner" style={{width:this.state.progress+"%"}}></div>
                </div>}
                {this.state.preloaded && <Router key="router"
                routes={this.state.routes}
                pages={this.state.pages}
                templates={this.state.templates}
                goTo={this.goTo}
                history={this.history}
                appState={this.state.appState}
                methods={this.methods}
                onRouteHasChanged={this.handleRouteHasChanged}
                />}
                {this.state.preloaded && <Countdown ref={this.countdown} onEnd={()=>this.methods.onShouldPlay(this.state.countdownId)}/>}
                {this.state.preloaded && <MainDrawer
                goTo={this.goTo}
                appState={this.state.appState}
                methods={this.methods}
                />}
                {this.state.preloaded && <div className={controlsClasses}>
                    <div className="main-controls-inner" onMouseEnter={this.controlLock} onMouseLeave={this.controlUnLock}>
                    <FullScreenBtn/>
                    <MuteBtn
                    onVolumeChange={this.handleVolumeChange}
                    onMute={this.handleMute}
                    onUnMute={this.handleUnMute}
                    volume={this.state.appState.mainVolume}
                    muted={this.state.appState.mainVolume===0}
                    />
                    </div>
                </div>}
                {modalContent && <Modal key={'modal-'+modalContent.id} content={modalContent} onOpen={this.onModalOpen} onClose={this.onModalClose} methods={this.methods} appState={this.state.appState}/>}
            </div>
        </div>;
    }
}

export default App;
